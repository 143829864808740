import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { CheckCircle } from 'react-feather'
import ReactMarkdown from 'react-markdown'
import { AButton } from '../atoms/AButton'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatOathChecklist = ({
  title,
  oaths,
  textBeforeOaths,
  first,
  onProceed = () => {},
}) => {
  const { user } = useUser()
  const [checkedOaths, setCheckedOaths] = useState(oaths.map(() => false))
  const [areAllOathsChecked, setAreAllOathsChecked] = useState(false)
  const [isOathed, setIsOathed] = useState(false)

  const toggleOathAtIndex = useCallback(
    index => {
      const newCheckedOaths = [...checkedOaths]
      newCheckedOaths[index] = !newCheckedOaths[index]
      const newAreAllOathsChecked = !newCheckedOaths.includes(false)

      setCheckedOaths(newCheckedOaths)
      setAreAllOathsChecked(newAreAllOathsChecked)
    },
    [checkedOaths]
  )

  const handleOnProceed = () => {
    if (areAllOathsChecked) {
      setIsOathed(true)
      onProceed()
    } else {
      alert('Please check all options first')
    }
  }

  return (
    <div
      className={classnames(
        'py-3 px-3.5 lg:py-4 lg:px-5 border rounded-lg transition-all duration-300',
        areAllOathsChecked ? 'border-quarterSpanishWhite' : 'border-persianRed'
      )}
    >
      <div className="flex flex-col">
        {title && <h4 className="text-xl font-p22 font-medium mb-3">{title}</h4>}
        {textBeforeOaths && <p className="mb-3">{textBeforeOaths}</p>}
        <ul>
          {oaths.map((oath, index) => (
            <li key={index} className="flex items-start mb-2.5">
              <div className="flex h-5 items-center">
                <input
                  id={oath}
                  name={oath}
                  type="checkbox"
                  disabled={isOathed}
                  checked={checkedOaths[index]}
                  onChange={() => {
                    toggleOathAtIndex(index)
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      toggleOathAtIndex(index)
                    }
                  }}
                  className="h-4 w-4 rounded border-2 border-silver text-codGray focus:ring-codGray cursor-pointer checked:border-codGray disabled:cursor-auto"
                />
              </div>
              <label htmlFor={oath} className="ml-3">
                <ReactMarkdown
                  className={classnames('text-sm font-medium', !isOathed ? 'cursor-pointer' : '')}
                >
                  {_.template(oath)({ user })}
                </ReactMarkdown>
              </label>
            </li>
          ))}
        </ul>
        {!isOathed ? (
          <AButton
            small
            className="mt-2 text-gray-900"
            disabled={!areAllOathsChecked}
            outline={!areAllOathsChecked}
            onClick={handleOnProceed}
          >
            Confirm
          </AButton>
        ) : (
          <div className="flex items-center justify-center mt-2 py-2">
            <CheckCircle size={20} />
            <span className="ml-2 pt-px uppercase font-medium">Confirmed</span>
          </div>
        )}
      </div>
    </div>
  )
}

MChatOathChecklist.propTypes = {
  title: PropTypes.string,
  textBeforeOaths: PropTypes.string,
  oaths: PropTypes.array,
  onProceed: PropTypes.func.isRequired,
  first: PropTypes.bool,
}
