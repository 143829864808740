import React from 'react'
import classNames from 'classnames'
import { useAuthor } from '@edwin/react-sdk'

import { AChatAuthor } from '../atoms/AChatAuthor'
import { ATypingIndicator } from '../atoms/ATypingIndicator'

export const MChatMsgWrapper = ({
  children,
  first = false,
  strong = false,
  fullWidth = false,
  isTyping = false,
}) => {
  const { author } = useAuthor()

  return (
    <div className={classNames('flex pb-2')}>
      {author && first && <AChatAuthor author={author} />}
      <div
        className={classNames(
          'inline-block max-w-[280px] text-base text-gray-900 leading-[20px] bg-white rounded-lg overflow-hidden',
          !fullWidth || isTyping ? 'lg:max-w-md' : 'lg:max-w-none',
          !isTyping && 'animate-fade-in opacity-0',
          author && (first ? 'ml-2' : 'ml-9'),
          first && 'rounded-tl-none',
          strong && 'font-bold'
        )}
      >
        {isTyping ? <ATypingIndicator /> : children}
      </div>
    </div>
  )
}
