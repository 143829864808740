import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const AAvatar = ({ firstName = '', lastName = '', className }) => {
  return (
    <div
      className={classnames(
        'flex items-center justify-center w-7 h-7 font-semi text-xs rounded-full',
        {
          [className]: !!className,
        }
      )}
    >
      {firstName.charAt(0).toUpperCase()} {lastName.charAt(0).toUpperCase()}
    </div>
  )
}

AAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  className: PropTypes.string,
}
