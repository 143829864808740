import { useCallback, useEffect, useRef, useState } from 'react'
import { useChatEngine } from '@edwin/react-sdk'

export const useMessages = ({
  initialMessages,
  completionMeta,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  triggerConfetti = () => {},
  continuousWaitFlag = true,
}) => {
  const finishProps = useRef(null)
  const [messages, setMessages] = useState([])

  const handleOnFinish = useCallback((msgs, oaths, questions) => {
    finishProps.current = { msgs, oaths, questions }
  }, [])

  const engineMessages = useChatEngine({
    initialMessages,
    onUserAction,
    onProgress,
    onFinish: handleOnFinish,
    onExit,
    onStepUpdated,
    onQuestion,
    continuousWaitFlag,
  })

  useEffect(() => {
    const lastEngineMessage = engineMessages[engineMessages.length - 1]

    if (lastEngineMessage) {
      setMessages(prev => {
        const lastMessage = prev[prev.length - 1]

        if (!!lastMessage && lastMessage.id === lastEngineMessage.id) {
          return prev
        }

        return [
          ...prev,
          {
            ...lastEngineMessage,
            ...(lastEngineMessage.end && {
              onRender: () => {
                setMessages(prev => [
                  ...prev,
                  {
                    id: 'custom-end-msg',
                    renderId: 'custom-end-msg',
                    type: 'bot-component',
                    componentName: 'congratulations',
                    componentOptions: {
                      triggerConfetti,
                      completionMeta,
                      onFinish: () => {
                        const { msgs = [], oaths = [], questions = [] } = finishProps?.current

                        onFinish(msgs, oaths, questions)
                      },
                    },
                  },
                ])
              },
            }),
          },
        ]
      })
    }
  }, [completionMeta, engineMessages, onFinish, triggerConfetti])

  return messages
}
