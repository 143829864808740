import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { X, ArrowLeft, ZoomOut, ChevronLeft } from 'react-feather'

export const ACloseIcon = ({ iconName = 'arrowLeft', className = '', onClick = () => {} }) => {
  const icons = {
    arrowLeft: <ArrowLeft />,
    chevronLeft: <ChevronLeft />,
    x: <X />,
    zoomOut: <ZoomOut />,
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        'flex items-center justify-center w-10 h-10 bg-pelorous/30 backdrop-filter backdrop-opacity-80 backdrop-blur-md cursor-pointer rounded-full',
        'focus:outline-none focus:ring-2 focus:ring-codGray',
        className
      )}
    >
      {icons[iconName]}
    </button>
  )
}

ACloseIcon.propTypes = {
  iconName: PropTypes.oneOf(['arrowLeft', 'chevronLeft', 'x', 'zoomOut']),
  className: PropTypes.string,
  onClick: PropTypes.func,
}
