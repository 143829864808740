import React from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import { Dialog, Transition } from '@headlessui/react'

export const OModal = ({ isOpen, onClose, children }) => {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
        initialFocus={false}
        open={isOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900/75" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block w-full max-w-md pt-8 p-6 overflow-hidden text-left text-codGray align-middle transition-all transform bg-quarterSpanishWhite shadow-xl rounded-lg">
              <div className="absolute top-2 right-2">
                <button
                  onClick={onClose}
                  className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray"
                >
                  <X className="h-6 w-6 hover:text-gray-600" />
                </button>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

OModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
}
