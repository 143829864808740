import React from 'react'
import classnames from 'classnames'

import { Rings } from 'react-loader-spinner'

export const AButton = ({
  icon,
  loadingIcon = <Rings type="Rings" color="white" height={24} width={24} />,
  overwriteStyles = false,
  isLoading,
  className = '',
  children,
  type = 'button',
  fullBorderRadius = false,
  primary = true,
  outline = false,
  small = false,
  disabled = false,
  warning = false,
  onClick = () => {},
  ...props
}) => {
  const buttonClassNames = overwriteStyles
    ? className
    : `flex justify-center px-3 py-1.5 text-sm leading-6 rounded-full focus:outline-none ${
        primary && !outline && !warning
          ? 'border-2 border-codGray bg-codGray text-white shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-codGray'
          : ''
      } ${
        outline
          ? 'border-codGray text-codGray hover:text-white hover:bg-codGray focus:ring-2 focus:ring-codGray focus:ring-offset-2 focus:text-white focus:bg-codGray'
          : ''
      } ${outline && (small ? 'border' : 'border-2')} ${
        warning ? 'text-white focus:outline-none border-red-400 text-red-400' : ''
      } ${className || ''} ${disabled ? 'opacity-50' : ''}`

  return (
    <button
      onClick={!isLoading ? onClick : undefined}
      type={type}
      className={classnames(buttonClassNames, isLoading ? 'bg-opacity-50 pointer-events-none' : '')}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <>{Boolean(loadingIcon) && loadingIcon}</>
      ) : (
        <>
          {Boolean(icon) && <div className="mr-2">{icon}</div>}
          <span className="text-center w-full inline-block">{children}</span>
        </>
      )}
    </button>
  )
}
