import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { generateRoute, ROUTES, ROUTE_NAMES, getLocationRouteName } from '@const/Routes'
import { useSelectedTopic, useProgramTimeline } from '@hooks'
import classnames from 'classnames'

import { AHeading } from '@atoms/AHeading'
import { AIconChevronLeft } from '@atoms/AIcons'
import { MSlider } from '@molecules/MSlider'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OTopicMissionCover } from '@organisms/OTopicMissionCover'
import { OTopicModal } from '@organisms/OTopicModal'
import { OTopicSkeleton } from '@organisms/OTopicSkeleton'

import { trackEvent } from '@services/Analytics'
import ANALYTICS from '@utils/constants/analytics'

export const PTopic = ({ isLoading }) => {
  const { topicId = '' } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  // const selectedTopic = useSelectedTopic(topicId)
  const { selectedTopic } = useProgramTimeline(topicId)

  const { name } = selectedTopic
  const nameRef = useRef()
  nameRef.current = name

  const activeItem = useMemo(() => {
    if (!selectedTopic) {
      return 0
    }

    return selectedTopic.missionsAndSeries?.findIndex(({ isCurrent }) => isCurrent) || 0
  }, [selectedTopic])

  const handleClick = useCallback(
    missionId => {
      navigate(generateRoute(ROUTES.PROGRAM_TOPIC_MISSION, { topicId, missionId }))
    },
    [navigate, topicId]
  )

  const handleGoBack = useCallback(() => {
    navigate(ROUTES.PROGRAM)
  }, [navigate])

  useEffect(() => {
    const pageTracker = getLocationRouteName(location?.pathname)

    if (pageTracker === ROUTE_NAMES.PROGRAM_TOPIC) {
      trackEvent(ANALYTICS.PROGRAM_TOPIC_OPEN, { title: nameRef.current })
    }
  }, [location.pathname])

  useEffect(() => {
    return () => {
      const pageTracker = getLocationRouteName(location?.pathname)
      if (pageTracker === ROUTE_NAMES.PROGRAM_TOPIC) {
        trackEvent(ANALYTICS.PROGRAM_TOPIC_CLOSE, { title: nameRef.current })
      }
    }
  }, [])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OTopicSkeleton />
      </OPageWrapper>
    )
  }

  if (!selectedTopic) {
    return null
  }

  return (
    <OPageWrapper>
      <div className="flex items-center">
        <button
          onClick={handleGoBack}
          className="flex items-center cursor-pointer rounded-sm focus:outline-none focus:ring-2 focus:ring-codGray"
        >
          <AIconChevronLeft className="mr-2.5" />
          <AHeading className="mr-2">{selectedTopic?.name}</AHeading>
        </button>
      </div>
      <MSlider
        startIndex={activeItem}
        className="mt-4 -mx-3 sm:-mx-5 mb-10"
        sliderClassName="pr-3 lg:pr-1"
        leftPaddingPlaceholder={<div className="ml-4" />}
      >
        {selectedTopic?.missionsAndSeries?.map((missionOrSeries, index) => (
          <OTopicMissionCover
            key={index}
            mission={missionOrSeries}
            className={classnames('snap-center flex-shrink-0 mx-1.5 last:mr-0')}
            onClick={() => {
              handleClick(missionOrSeries?.id)
            }}
          />
        ))}
      </MSlider>

      <OTopicModal />
    </OPageWrapper>
  )
}
