import { useCallback, useState } from 'react'
import { useSummary } from '@edwin/react-sdk'
import _ from 'lodash'

import Lottie from 'lottie-react'
import { AButton } from '../atoms/AButton'

import lottieAnimation from '../../assets/lottie/animation_lnukuo2q.json'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatMissionSummary = ({
  completionMeta,
  triggerConfetti = () => {},
  onFinish = () => {},
}) => {
  const {
    missionTitle,
    translations = {
      congrats: 'Well Done!',
      description:
        'Tracking your personal care products is a powerful way to understand what you use and how it may impact your health.',
      completed: 'You have completed {{name}}.',
      ctaText: 'Finish',
    },
  } = useSummary()

  // const [wasConfetti, setWasConfetti] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const handleOnFinish = useCallback(() => {
    if (!isFinished) {
      onFinish()
      setIsFinished(true)
    }
  }, [isFinished, onFinish])

  // useEffect(() => {
  //   if (!wasConfetti) {
  //     setTimeout(() => {
  //       triggerConfetti()
  //       setWasConfetti(true)
  //     }, 500)
  //   }
  // }, [triggerConfetti, wasConfetti])

  return (
    <div className="flex flex-col items-center py-3 px-3.5 lg:px-5 text-center">
      <Lottie animationData={lottieAnimation} />
      <div className="text-2xl lg:text-4xl font-p22">{translations.congrats}</div>
      <div className="mt-2.5 lg:mt-4 mb-4 lg:mb-7 lg:text-lg font-p22">
        {translations.description}
      </div>
      <div className="text-sm lg:text-base">
        {_.template(translations.completed)({ name: missionTitle })}
      </div>
      <AButton className="mt-3 lg:mt-7" onClick={handleOnFinish} disabled={isFinished}>
        <span className="px-4">{translations.ctaText}</span>
      </AButton>
    </div>
  )
}
