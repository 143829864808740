import { useMemo } from 'react'
import { useTranslations } from '@hooks'

import { useStore, useEphemeralStore } from '@store/useStore'
import useProgressStore from '@store/useProgressStore'

import { DateTime, Interval } from 'luxon'

const DEFAULT_FIRST_DAY_OF_PROGRAM = 1

export const useProgramTimeline = (topicId, missionId) => {
  const { t, translateData, currentLanguage } = useTranslations()

  const dateNow = useEphemeralStore(state => state.dateNow)
  const userDidJoinOn = useStore(state => state.user?.didJoinOn)
  const topics = useProgressStore(state => state.userContent?.topics)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const getMission = useProgressStore(state => state.getMission)

  const currentProgramDay = useMemo(() => {
    if (!dateNow || !userDidJoinOn) {
      return DEFAULT_FIRST_DAY_OF_PROGRAM
    }

    const userDidJoinDateTime = DateTime.fromISO(userDidJoinOn)
    const howManyDaysAfterUserDidJoin = Interval.fromDateTimes(
      userDidJoinDateTime.startOf('day'),
      dateNow.startOf('day')
    ).length('days')

    return howManyDaysAfterUserDidJoin + 1
  }, [dateNow, userDidJoinOn])

  const availableTopics = useMemo(() => {
    if (!topics) {
      return []
    }

    return topics?.map((topic, index) => {
      const translatedTopic = translateData(topic)
      const topicProgress = getTopicProgress(topic.id)

      const missionsAndSeries = topic?.missionsAndSeries.map(mission => {
        const missionData = getMission(mission.id)
        const translatedMission = translateData(missionData)

        const { isCompleted = false } = topicProgress?.missionsAndSeries.find(
          ({ id }) => id === mission.id
        )

        const startsOn = DateTime.now()
          .startOf('day')
          .plus({ days: missionData?.howLongToWaitForUnlock - currentProgramDay || 0 })
          .toRelativeCalendar({ unit: 'days', locale: currentLanguage.code })

        return {
          topicId: topic.id,
          ...translatedMission,
          isCompleted,
          isCurrent: missionData?.howLongToWaitForUnlock === currentProgramDay,
          isLocked: missionData?.howLongToWaitForUnlock !== currentProgramDay,
          ...(missionData?.howLongToWaitForUnlock - currentProgramDay > 0 && {
            startsOn: t('missionStartsOn', { startsOn }),
          }),
        }
      })

      const topicStatuses = {
        isCompleted: false,
        isStarted: false,
      }
      const furthestTopicMission = [...missionsAndSeries].sort(
        (a, b) => b?.howLongToWaitForUnlock - a?.howLongToWaitForUnlock
      )[0]

      if (furthestTopicMission?.howLongToWaitForUnlock < currentProgramDay) {
        topicStatuses.isCompleted = true
      } else {
        const prevTopic = topics?.[index - 1]?.missionsAndSeries?.map(mission => {
          return getMission(mission.id)
        })
        const prevTopicFurthestMission = prevTopic
          ?.sort((a, b) => a?.howLongToWaitForUnlock - b?.howLongToWaitForUnlock)
          ?.slice(-1)?.[0]
        const endOfPrevTopic = prevTopicFurthestMission?.howLongToWaitForUnlock || 0

        if (endOfPrevTopic < currentProgramDay) {
          topicStatuses.isStarted = true
        }
      }

      return {
        ...translatedTopic,
        ...topicStatuses,
        missionsAndSeries,
      }
    })
  }, [
    topics,
    translateData,
    getTopicProgress,
    currentProgramDay,
    getMission,
    currentLanguage.code,
    t,
  ])

  const activeTopics = useMemo(() => {
    return availableTopics.filter(({ isStarted }) => isStarted)
  }, [availableTopics])

  const selectedTopic = useMemo(() => {
    return availableTopics.filter(({ id }) => id === topicId)?.[0]
  }, [availableTopics, topicId])

  const selectedMission = useMemo(() => {
    const mission = selectedTopic?.missionsAndSeries.find(({ id }) => id === missionId)

    return mission || {}
  }, [missionId, selectedTopic?.missionsAndSeries])

  return { currentProgramDay, availableTopics, activeTopics, selectedTopic, selectedMission }
}
