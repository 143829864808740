import React, { useState } from 'react'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { AChatUserInitials } from '../atoms/AChatUserInitials'
import { MChatUserOption } from './MChatUserOption'

export const MChatUserOptions = ({ options }) => {
  const { user } = useUser()
  const [chosenOption, setChosenOption] = useState(null)

  const handleClick = optionIndex => {
    const { onClick = () => {} } = options[optionIndex]

    if (chosenOption !== null) return

    setChosenOption(optionIndex)
    onClick()
  }

  return (
    <div className="flex items-end justify-end ml-auto pb-2 lg:pb-6">
      <div
        className={classNames(
          'flex flex-col items-end max-w-[240px]'
          // user?.initials && 'mr-2.5'
        )}
      >
        {options.map(({ label }, index) => (
          <MChatUserOption
            key={label}
            label={label}
            chosenOption={chosenOption}
            index={index}
            onClick={() => {
              handleClick(index)
            }}
          />
        ))}
      </div>
      {/*{user?.initials && <AChatUserInitials initials={user.initials} />}*/}
    </div>
  )
}
