import React, { useCallback, useState, useLayoutEffect, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { useForm, Controller } from 'react-hook-form'
import { signInWithPhoneNumber } from '@services/Authentication'
import { trackEvent } from '@services/Analytics'

import { useTranslations } from '@hooks'

import mobile from '@assets/images/flower/mobile.png'
import mobileSmall from '@assets/images/flower/mobile-placeholder.png'
import desktop from '@assets/images/flower/desktop.png'
import desktopSmall from '@assets/images/flower/desktop-placeholder.png'
import ProgressiveImage from 'react-progressive-graceful-image'
import { AButton } from '@atoms/AButton'
import { AFormInput } from '@atoms/AFormInput'

import { RecaptchaVerifier, auth } from '@services/Firebase'

import ANALYTICS from '@utils/constants/analytics'
import { ROUTES } from '@const/Routes'

export const PSignInWithPhoneNumber = () => {
  const { t } = useTranslations()
  const [isWorking, setIsWorking] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [recaptchaVerifierCode, setRecaptchaVerifierCode] = useState(null)
  const [isSmsCodeSend, setIsSmsCodeSend] = useState(false)
  const recaptchaVerifierRef = useRef(null)
  const recaptchaContainerRef = useRef(null)
  const confirmationResultRef = useRef(null)
  const isRecaptchaRenderedRef = useRef(false)

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      phoneNumber: '',
      smsCode: '',
    },
  })

  const handleSendSmsCode = useCallback(
    async phoneNumber => {
      try {
        setIsWorking(true)
        setErrorMsg(false)

        const result = await signInWithPhoneNumber({
          phoneNumber,
          verifierCode: recaptchaVerifierRef.current,
        })

        confirmationResultRef.current = result
        isRecaptchaRenderedRef.current = false
        setIsSmsCodeSend(true)
        setIsWorking(false)
      } catch (err) {
        console.log(err)
        setErrorMsg(t(err))
        setIsWorking(false)
      }
    },
    [t]
  )

  const handleSubmitCaptchaVerified = useCallback(
    async smsCode => {
      try {
        setIsWorking(true)
        setErrorMsg(false)

        await confirmationResultRef.current.confirm(smsCode)

        await trackEvent(ANALYTICS.SIGN_IN)

        setIsWorking(false)
      } catch (err) {
        setErrorMsg(t('error.signInPhone'))
        setIsWorking(false)
        await trackEvent(ANALYTICS.SIGN_IN_UNSUCCESSFUL)
      }
    },
    [t]
  )

  const onSubmitSentSmsCode = useCallback(
    data => {
      handleSendSmsCode(data.phoneNumber)
    },
    [handleSendSmsCode, recaptchaVerifierCode]
  )

  const onSubmitCaptchaVerified = useCallback(
    data => {
      handleSubmitCaptchaVerified(data.smsCode)
    },
    [handleSubmitCaptchaVerified]
  )

  const handleOpenResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(true)
  }, [])

  const handleCloseResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(false)
  }, [])

  useEffect(() => {
    if (!isWorking && !isSmsCodeSend && !isRecaptchaRenderedRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
        size: 'invisible',
        callback: appVerifier => {
          setRecaptchaVerifierCode(appVerifier)
        },
      })

      recaptchaVerifierRef.current.verify()

      isRecaptchaRenderedRef.current = true
    }
  }, [isWorking])

  return (
    <OPageWrapper
      isHeaderVisible={false}
      contentClassName="flex flex-col items-center justify-between min-h-screen pb-12 lg:py-12"
      pageIllustration={
        <div className="absolute -top-2.5 -left-4 w-[412px] md:w-[502px] lg:w-[611px]">
          <ProgressiveImage placeholder={mobileSmall} src={mobile}>
            {src => <img className="md:hidden w-full h-full object-cover" src={src} alt="" />}
          </ProgressiveImage>
          <ProgressiveImage placeholder={desktopSmall} src={desktop}>
            {src => <img className="hidden md:block w-full h-full object-cover" src={src} alt="" />}
          </ProgressiveImage>
        </div>
      }
    >
      <div className="mt-32 md:mt-28 lg:mt-20 flex flex-col items-center">
        <div className="text-4xl md:text-5xl font-p22 font-bold">{t('logo')}</div>
        <h2 className="mt-16 md:mt-14 text-center font-p22 text-3xl">{t('welcome')}</h2>
        <p className="text-center text-base">{t('getStarted')}</p>

        {!isSmsCodeSend && (
          <>
            <form
              className="w-[300px] mx-auto mt-4 space-y-4"
              onSubmit={handleSubmit(onSubmitSentSmsCode)}
            >
              <Controller
                control={control}
                name="phoneNumber"
                rules={{ required: t('required.phoneNumber') }}
                render={({ field, fieldState }) => (
                  <AFormInput
                    {...field}
                    id="phoneNumber"
                    placeholder={t('label.phoneNumber')}
                    type="tel"
                    autoComplete="tel"
                    errorMessage={fieldState.error && fieldState.error.message}
                  />
                )}
              />

              <div ref={recaptchaContainerRef} id="recaptcha-container"></div>

              <div className="pt-3 mx-8">
                <AButton
                  className="w-full"
                  type="submit"
                  isLoading={isWorking}
                  disabled={!recaptchaVerifierCode}
                >
                  {'Get verification code'}
                </AButton>
              </div>
            </form>
            <div>
              {errorMsg && (
                <div className="mt-6 flex flex-1 flex-grow text-center">
                  <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
                </div>
              )}
            </div>
          </>
        )}

        {isSmsCodeSend && (
          <>
            {' '}
            <form
              className="w-[300px] mx-auto mt-4 space-y-4"
              onSubmit={handleSubmit(onSubmitCaptchaVerified)}
            >
              <Controller
                control={control}
                name="smsCode"
                rules={{ required: t('required.smsCode') }}
                render={({ field, fieldState }) => (
                  <AFormInput
                    {...field}
                    id="smsCode"
                    placeholder={t('smsCodeReceived')}
                    type="number"
                    errorMessage={fieldState.error && fieldState.error.message}
                  />
                )}
              />

              <div className="pt-3 mx-8">
                <AButton
                  className="w-full"
                  type="submit"
                  isLoading={isWorking}
                  disabled={!recaptchaVerifierCode}
                >
                  {t('signIn')}
                </AButton>
              </div>
            </form>
            <div>
              {errorMsg && (
                <div className="mt-6 flex flex-1 flex-grow text-center">
                  <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col items-center justify-center mt-6">
        {isSmsCodeSend && (
          <div>
            <AButton
              onClick={() => {
                setIsSmsCodeSend(false)
              }}
              outline
              small
              className="mx-auto px-8 font-light"
            >
              {t('generateNewSmsCode')}
            </AButton>
          </div>
        )}

        <Link
          to={ROUTES.SIGN_IN_WITH_EMAIL_AND_PASSWORD}
          className="relative mt-3 text-sm hover:underline font-medium rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray"
        >
          {t('signInWithEmailAndPassword')}
        </Link>
      </div>
    </OPageWrapper>
  )
}
