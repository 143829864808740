import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import ProgressiveImage from 'react-progressive-graceful-image'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { signInWithEmailAndPassword } from '@services/Authentication'

import { useTranslations } from '@hooks'

import mobile from '@assets/images/flower/mobile.png'
import mobileSmall from '@assets/images/flower/mobile-placeholder.png'
import desktop from '@assets/images/flower/desktop.png'
import desktopSmall from '@assets/images/flower/desktop-placeholder.png'

import { AButton } from '@atoms/AButton'
import { AFormInput } from '@atoms/AFormInput'
import { OResetPasswordModal } from '@organisms/OResetPasswordModal'
import { ROUTES } from '@const/Routes'

export const PSignInWithEmailAndPassword = () => {
  const { t } = useTranslations()

  const [isWorking, setIsWorking] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const handleSignIn = useCallback(async (email, password) => {
    try {
      setIsWorking(true)
      setErrorMsg(false)
      await signInWithEmailAndPassword({ email, password })
      setIsWorking(false)
    } catch (err) {
      setErrorMsg(t('error.signIn'))
      setIsWorking(false)
    }
  }, [])

  const onSubmit = useCallback(
    data => {
      handleSignIn(data.email, data.password)
    },
    [handleSignIn]
  )

  const handleOpenResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(true)
  }, [])

  const handleCloseResetPasswordModal = useCallback(() => {
    setIsResetPasswordModalOpen(false)
  }, [])

  return (
    <OPageWrapper
      isHeaderVisible={false}
      contentClassName="flex flex-col items-center justify-between min-h-screen pb-12 lg:py-12"
      pageIllustration={
        <div className="absolute -top-2.5 -left-4 w-[412px] md:w-[502px] lg:w-[611px]">
          <ProgressiveImage placeholder={mobileSmall} src={mobile}>
            {src => <img className="md:hidden w-full h-full object-cover" src={src} alt="" />}
          </ProgressiveImage>
          <ProgressiveImage placeholder={desktopSmall} src={desktop}>
            {src => <img className="hidden md:block w-full h-full object-cover" src={src} alt="" />}
          </ProgressiveImage>
        </div>
      }
    >
      <div className="mt-32 md:mt-28 lg:mt-20 flex flex-col items-center">
        <div className="text-4xl md:text-5xl font-p22 font-bold">{t('logo')}</div>
        <h2 className="mt-16 md:mt-14 text-center font-p22 text-3xl">{t('welcome')}</h2>
        <p className="text-center text-base">{t('getStarted')}</p>
        <form className="w-[300px] mx-auto mt-4 space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            rules={{ required: t('required.email') }}
            render={({ field, fieldState }) => (
              <AFormInput
                {...field}
                id="email"
                placeholder={t('label.email')}
                type="email"
                autoComplete="email"
                errorMessage={fieldState.error && fieldState.error.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: t('required.password') }}
            render={({ field, fieldState }) => (
              <AFormInput
                {...field}
                id="password"
                placeholder={t('label.password')}
                type="password"
                autoComplete="current-password"
                errorMessage={fieldState.error && fieldState.error.message}
              />
            )}
          />

          <div className="pt-3 mx-8">
            <AButton className="w-full" type="submit" isLoading={isWorking}>
              {t('signIn')}
            </AButton>
          </div>
        </form>
        <div>
          {errorMsg && (
            <div className="mt-6 flex flex-1 flex-grow text-center">
              <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mt-6">
        <div>
          <AButton
            onClick={handleOpenResetPasswordModal}
            outline
            small
            className="mx-auto px-8 font-light"
          >
            {t('resetPassword')}
          </AButton>
        </div>
        <Link
          to={ROUTES.SIGN_IN}
          className="relative mt-3 text-sm hover:underline font-medium rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray"
        >
          {t('signInWithPhoneNumber')}
        </Link>
      </div>

      <OResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
      />
    </OPageWrapper>
  )
}
