import { MChatUserOptions } from '../components/molecules/MChatUserOptions'
import { MChatBotMsg } from '../components/molecules/MChatBotMsg'
import { MChatMsgError } from '../components/molecules/MChatMsgError'
import { MChatGif } from '../components/molecules/MChatGif'
import { MChatImage } from '../components/molecules/MChatImage'
import { MChatFormatted } from '../components/molecules/MChatFormatted'
import { MChatInfographicIllustration } from '../components/molecules/MChatInfographicIllustration'
import { MChatInfographicAnimatedNumber } from '../components/molecules/MChatInfographicAnimatedNumber'
import { MChatInfographicPiechart } from '../components/molecules/MChatInfographicPiechart'
import { MChatInteractiveChecklist } from '../components/molecules/MChatInteractiveChecklist'
import { MChatOathChecklist } from '../components/molecules/MChatOathChecklist'
import { MChatVideo } from '../components/molecules/MChatVideo'
import { MChatAudio } from '../components/molecules/MChatAudio'
import { MChatGoal } from '../components/molecules/MChatGoal'
import { MChatDefaultComponent } from '../components/molecules/MChatDefaultComponent'
import { MChatMissionSummary } from '../components/molecules/MChatMissionSummary'
import { MChatQuestionnaireAnswersSelectable } from '../components/molecules/MChatQuestionnaireAnswersSelectable'

export const configChatComponents = {
  'user-options': MChatUserOptions,
  bot: MChatBotMsg,
  video: MChatVideo,
  image: MChatImage,
  gif: MChatGif,
  formatted: MChatFormatted,
  'formatted-list': MChatFormatted,
  'infographic-illustration': MChatInfographicIllustration,
  'infographic-animated-number': MChatInfographicAnimatedNumber,
  'infographic-piechart': MChatInfographicPiechart,
  'interactive-checklist': MChatInteractiveChecklist,
  'oath-checklist': MChatOathChecklist,
  error: MChatMsgError,
  goal: MChatGoal,
  audio: MChatAudio,
  congratulations: MChatMissionSummary,
  'questionnaire-answers-selectable': MChatQuestionnaireAnswersSelectable,
  // '': MChatDefaultComponent,
}
