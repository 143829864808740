import { useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuestionnaire } from '@edwin/react-sdk'

import { useStore } from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import useQuestionnaireStore from '@store/useQuestionnaireStore'
import { ROUTES, generateRoute } from '@const/Routes'
import { sleep } from '@utils/helpers'
import { useHandleScrollbarSpace } from '@hooks/'

import { useTranslations } from '@hooks'

import { DateTime } from 'luxon'
import { Mission } from '@beautyedit/react-web'
import { OMissionSkeleton } from '@organisms/OMissionSkeleton'
import { trackEvent } from '@services/Analytics'
import ANALYTICS from '@utils/constants/analytics'

export const POnboard = ({ isLoading }) => {
  const { t, translateData } = useTranslations()
  const navigate = useNavigate()

  useHandleScrollbarSpace()

  const user = useStore(state => state.user)
  const addUserQueueTask = useStore(state => state.addQueueTask)
  const addQuestionnaireQueueTask = useQuestionnaireStore(state => state.addQueueTask)
  const updateUser = useStore(state => state.updateUser)
  const onboardMission = useProgressStore(state => state.userContent?.onboardingMission)

  const onQuestionAnswered = useCallback(questionAnswered => {
    console.log('--- question answered ---', questionAnswered)
  }, [])

  const { updateQuestionnaire, getQuestionnairePayload } = useQuestionnaire(onQuestionAnswered)

  const translatedOnboardMission = useMemo(() => {
    return translateData(onboardMission)
  }, [onboardMission, translateData])

  useEffect(() => {
    trackEvent(ANALYTICS.ONBOARDING_STARTED)
  }, [])

  const handleFinish = useCallback(
    async (msgs, oaths, questions) => {
      const questionnairePayload = getQuestionnairePayload(questions)

      if (questionnairePayload) {
        addQuestionnaireQueueTask({
          payload: {
            createdOn: DateTime.now().toJSDate(),
            missionId: onboardMission.id,
            userId: user.id,
            ...questionnairePayload,
          },
        })
      }

      await sleep(3000)

      addUserQueueTask({
        userId: user.id,
        payload: {
          isOnboarded: true,
        },
      })

      updateUser({ isOnboarded: true })

      trackEvent(ANALYTICS.ONBOARDING_COMPLETED)

      navigate({ pathname: generateRoute(ROUTES.ROOT) })
    },
    [
      addQuestionnaireQueueTask,
      addUserQueueTask,
      getQuestionnairePayload,
      navigate,
      onboardMission?.id,
      updateUser,
      user?.id,
    ]
  )

  if (isLoading) {
    return <OMissionSkeleton />
  }

  if (!translatedOnboardMission) {
    return null
  }

  return (
    <Mission
      user={user}
      mission={translatedOnboardMission}
      onFinish={handleFinish}
      onQuestion={updateQuestionnaire}
      translations={{
        author: t('mission.author'),
        steps: t('mission.steps'),
        congrats: t('summaryComponent.congrats'),
        youEarned: t('summaryComponent.youEarned'),
        points: t('summaryComponent.points'),
        totalPoints: t('summaryComponent.totalPoints'),
        ctaText: t('summaryComponent.ctaText'),
      }}
      shouldDisplayCover={false}
      shouldDisplayCloseIcon={false}
    />
  )
}
