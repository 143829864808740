const CONFIG = {
  firebase: {
    apiKey: "AIzaSyCtSj1UfnkN4fv_bMbBRZuAQzQaX-zw4ro",
    authDomain: "poppy-6b07f.firebaseapp.com",
    projectId: "poppy-6b07f",
    storageBucket: "poppy-6b07f.appspot.com",
    messagingSenderId: "750264804875",
    appId: "1:750264804875:web:598e5fd96a10ac335f490e",
    measurementId: "G-M315TC0093"
  },
  sentry: {
    dsn: '',
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  },
  mixpanel: {
    token: '',
  },
  analyticsEnabled: false,
  timeTravelEnabled: false,
  cloudFunctionsUrl: 'https://api.edwin.cloud/v1',
  connectFirebaseEmulators: false,
}

export default CONFIG
