import React, { forwardRef, useCallback, useState } from 'react'
import { getSanityImage } from '@edwin/react-sdk'
import classnames from 'classnames'

import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'
import { MLockedMissionModal } from '@molecules/MLockedMissionModal'

export const OTopicMissionCover = forwardRef(({ mission, onClick = () => {}, className }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { coverImageUrl, coverImageMeta, isCurrent, isCompleted, isLocked, startsOn } = mission
  // const isLocked = !isCompleted && !isCurrent

  const placeholderImage = getSanityImage(coverImageUrl, {
    width: 293,
    height: 230,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
    quality: 30,
  })

  const coverImage = getSanityImage(coverImageUrl, {
    width: 293 * 3,
    height: 230 * 3,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
  })

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  return (
    <>
      <button
        ref={ref}
        onClick={isLocked ? handleOpenModal : onClick}
        className={classnames(
          'relative flex flex-col items-start bg-white rounded-lg !rounded-tl-none overflow-hidden transition-opacity',
          'my-1 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-codGray cursor-pointer',
          'w-64 md:w-[293px]',
          isLocked ? '' : 'hover:opacity-90',
          className
        )}
      >
        <div className="absolute top-3 right-3 flex flex-col">
          {isCompleted && <AIconCompleted className="w-5 h-5 mb-1.5 bg-codGray/90 opacity-80" />}
          {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500 opacity-80" />}
        </div>
        {coverImage && (
          <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
            {src => <img className="w-full h-[230px] object-cover" src={src} alt="mission cover" />}
          </ProgressiveImage>
        )}
        <div className="pt-6 px-4 pb-3 text-left">
          <h4
            className={classnames(
              'text-xl md:text-2xl font-p22 font-medium line-clamp-4',
              isLocked && 'opacity-50'
            )}
          >
            {mission.title}
          </h4>
          {startsOn && <p className="mt-2 text-sm font-medium uppercase opacity-50">{startsOn}</p>}
        </div>
      </button>

      <MLockedMissionModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  )
})
