import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { PieChart } from 'react-minimal-pie-chart'
import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

const NEAR_WHITE = '#EEECE9'
const LIGHT_VIOLET = '#F0CE57'

export const MChatInfographicPiechart = ({
  data,
  totalValue = 100,
  label,
  descriptionMarkdown,
}) => {
  const { user } = useUser()

  const pieChartValue = useMemo(() => parseInt(data[0]?.value), [data])

  return (
    <div className="flex">
      <div className="flex-shrink-0 w-32 lg:w-36 mx-auto p-4 lg:py-2 lg:px-4">
        <PieChart
          data={[
            { color: NEAR_WHITE, value: totalValue - pieChartValue },
            { color: LIGHT_VIOLET, value: pieChartValue },
          ]}
          startAngle={-90}
          lengthAngle={-360}
          totalValue={totalValue}
          lineWidth={14}
          label={() => label}
          labelStyle={{
            fontFamily: 'P22Mackinac',
            fontSize: '32px',
            fontWeight: 'bold',
            x: '50%',
            y: '50%',
            fill: '#191919',
          }}
          labelPosition={0}
          animate
          animationDuration={800}
          rounded
        />
      </div>
      <div className="py-3 px-3.5 lg:px-5">
        <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
      </div>
    </div>
  )
}

MChatInfographicPiechart.propTypes = {
  label: PropTypes.string.isRequired,
  descriptionMarkdown: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      // color: PropTypes.string,
    })
  ),
  totalValue: PropTypes.number,
}
