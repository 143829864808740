import { useTranslations, useProgramTimeline } from '@hooks'

import { AHeading } from '@atoms/AHeading'
import { MProgramTopic } from '@molecules/MProgramTopic'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OProgressSkeleton } from '@organisms/OProgressSkeleton'

export const PProgress = ({ isLoading }) => {
  const { t } = useTranslations()

  const { availableTopics } = useProgramTimeline()

  if (isLoading) {
    return (
      <OPageWrapper>
        <OProgressSkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper>
      <AHeading>{t('progressHeading')}</AHeading>
      <div className="max-w-sm md:max-w-3xl lg:max-w-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-3 md:gap-4 lg:gap-y-8 lg:gap-x-5 mt-4 mb-6">
        {availableTopics?.map(topic => (
          <MProgramTopic key={topic.id} topic={topic} />
        ))}
      </div>
    </OPageWrapper>
  )
}
