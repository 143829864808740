import { create } from 'zustand'
import _ from 'lodash'
import { persist, createJSONStorage } from 'zustand/middleware'
import { doc, getDoc } from 'firebase/firestore'
import { nanoid } from 'nanoid/non-secure'
import { DateTime } from 'luxon'

import { createEphemeralStore } from '@edwin/stores'
import { firestoreDb, COLLECTIONS, updateUserData } from '@services/Firebase'

export const useStore = create(
  persist(
    (set, get) => ({
      lastDateNow: null,
      user: null,
      accessToken: null,
      organization: null,
      team: null,
      isAuthDataLoaded: false,
      requestsQueue: [],
      isQueueProcessing: false,
      language: 'en',
      newLanguage: null,

      loadUserAndOrganization: async userId => {
        if (!userId) return

        const userRef = doc(firestoreDb, COLLECTIONS.users, userId)
        const userSnap = await getDoc(userRef)

        if (userSnap.exists()) {
          const userData = userSnap.data()

          const orgRef = doc(firestoreDb, COLLECTIONS.organizations, userData?.organizationId)
          const orgSnap = await getDoc(orgRef)

          const teamRef = doc(firestoreDb, COLLECTIONS.teams, userData?.teamId)
          const teamSnap = await getDoc(teamRef)

          if (orgSnap.exists() && teamSnap.exists()) {
            const userData = userSnap.data()
            const userInitials =
              userData.firstName.slice(0, 1) + userData.lastName.slice(0, 1) || ''

            const organizationData = orgSnap.data()
            const teamData = teamSnap.data()
            const language = userData.language

            set({
              user: {
                ...userData,
                initials: userInitials,
                didJoinOn: !isNaN(userData?.didJoin)
                  ? DateTime.fromMillis(userData?.didJoinOn?.seconds * 1000).toISO()
                  : null,
                id: userSnap.id,
              },
              organization: { ...organizationData, id: orgSnap.id },
              team: { ...teamData, id: teamSnap.id },
              isAuthDataLoaded: true,
            })

            if (language) {
              set({ language })
            }
          }
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      },
      loadUserToken: async authUser => {
        const tokenResult = await authUser.getIdTokenResult(true)

        set({ accessToken: tokenResult.claims.accessToken })
      },
      /**
       *
       * @param {*} task
       */
      addQueueTask: task => {
        const randomId = nanoid()
        const modifiedTask = {
          ...task,
          id: randomId,
        }
        const queue = [...get().requestsQueue, modifiedTask]

        set({ requestsQueue: queue })
      },
      /**
       *
       * @param {*} taskId
       */
      removeTaskFromQueue: taskId => {
        const tasks = [...get().requestsQueue]
        const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

        set({ requestsQueue: modifiedTasks })
      },
      /**
       *
       * @returns
       */
      processQueue: async () => {
        const isQueueProcessing = get().isQueueProcessing
        const tasks = get().requestsQueue

        if (isQueueProcessing) {
          return
        }

        set({ isQueueProcessing: true })

        for (const task of tasks) {
          try {
            await updateUserData(task.userId, task.payload)

            get().removeTaskFromQueue(task.id)
          } catch (err) {
            console.error('UserQueue processing error', err)
          }
        }

        set({ isQueueProcessing: false })
      },
      /**
       *
       * @param {*} dateNow<Date>
       */
      updateLastDateNow: dateNow => {
        const dateNowFinal = dateNow
          ? dateNow.startOf('day').plus({ seconds: 1 })
          : DateTime.now().startOf('day').plus({ seconds: 1 })

        set({ lastDateNow: dateNowFinal })
      },
      updateUser: (userData = {}) => {
        set({ user: { ...get().user, ...userData } })
      },
      setLanguage: language => {
        set({ language })
      },
      setNewLanguage: newLanguage => {
        set({ newLanguage })
      },

      clearLocalStorage: () => {
        // default values
        set({
          lastDateNow: null,
          user: null,
          accessToken: null,
          organization: null,
          team: null,
          isAuthDataLoaded: false,
          requestsQueue: [],
          isQueueProcessing: false,
          language: 'en',
          newLanguage: null,
        })
      },
    }),
    {
      name: 'app-storage',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

export const useEphemeralStore = createEphemeralStore()

export default useStore
