import { useTranslations } from '@hooks/'

import { OModal } from '@organisms/OModal'

export const MLockedMissionModal = ({ isOpen, onClose }) => {
  const { t } = useTranslations()

  return (
    <OModal isOpen={isOpen} onClose={onClose}>
      <div className="max-w-[295px] md:max-w-sm mx-auto py-4 md:py-6">
        <svg
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mb-5 md:mb-8 mx-auto"
        >
          <g opacity="0.6">
            <path
              d="M48.5013 60.625V68.7083M24.2513 84.875H72.7513C74.8951 84.875 76.9512 84.0234 78.4671 82.5074C79.983 80.9915 80.8346 78.9355 80.8346 76.7917V52.5417C80.8346 50.3978 79.983 48.3418 78.4671 46.8259C76.9512 45.31 74.8951 44.4583 72.7513 44.4583H24.2513C22.1075 44.4583 20.0514 45.31 18.5355 46.8259C17.0196 48.3418 16.168 50.3978 16.168 52.5417V76.7917C16.168 78.9355 17.0196 80.9915 18.5355 82.5074C20.0514 84.0234 22.1075 84.875 24.2513 84.875ZM64.668 44.4583V28.2917C64.668 24.004 62.9647 19.8919 59.9329 16.8601C56.901 13.8283 52.789 12.125 48.5013 12.125C44.2136 12.125 40.1016 13.8283 37.0697 16.8601C34.0379 19.8919 32.3346 24.004 32.3346 28.2917V44.4583H64.668Z"
              stroke="#191919"
              strokeWidth="2.06047"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        <div className="text-center">
          <span className="text-xs md:text-sm font-medium tracking-[0.6px] uppercase opacity-80">
            {t('lockedMission.status')}
          </span>
          <h4 className="mt-3 md:mt-1 mb-7 md:mb-8 text-2xl md:text-3xl font-p22">
            {t('lockedMission.title')}
          </h4>
          <p className="md:text-lg font-p22">{t('lockedMission.description')}</p>
        </div>
      </div>
    </OModal>
  )
}
