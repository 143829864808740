import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useGoal, useQuestionnaire } from '@edwin/react-sdk'
import { useHandleScrollbarSpace, useProgramTimeline, useSelectedMission } from '@hooks/'
import { DateTime } from 'luxon'

import useStore from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import useGoalsStore from '@store/useGoalsStore'
import useQuestionnaireStore from '@store/useQuestionnaireStore'
// import usePointsStore from '@store/usePointsStore'

import { useTranslations } from '@hooks'

import { ROUTES, generateRoute } from '@const/Routes'

import { Mission } from '@beautyedit/react-web'
import { AButton } from '@atoms/AButton'
import { OModal } from '@organisms/OModal'
import { OMissionSkeleton } from '@organisms/OMissionSkeleton'

export const PMission = ({ isLoading }) => {
  const { t } = useTranslations()
  const { missionId = '', topicId = '' } = useParams()
  const { createGoalPayload } = useGoal()
  const navigate = useNavigate()
  const location = useLocation()

  // const selectedMission = useSelectedMission(topicId, missionId)
  const { selectedMission } = useProgramTimeline(topicId, missionId)

  useHandleScrollbarSpace()

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false)

  const user = useStore(state => state.user)

  const addQueueTask = useQuestionnaireStore(state => state.addQueueTask)

  const setMissionProgress = useProgressStore(state => state.setMissionProgress)

  const createGoalAndGoalInstances = useGoalsStore(state => state.createGoalAndGoalInstances)
  const doesGoalExist = useGoalsStore(state => state.doesGoalExist)

  // const getUserPoints = usePointsStore(state => state.getUserPoints)
  // const updateUserPoints = usePointsStore(state => state.updateUserPoints)

  const onQuestionAnswered = useCallback(questionAnswered => {
    console.log('--- question answered ---', questionAnswered)
  }, [])

  const { updateQuestionnaire, getQuestionnairePayload } = useQuestionnaire(onQuestionAnswered)

  const previousPathName = useMemo(() => {
    if (!missionId) return ROUTES.ROOT

    const pathName = location?.pathname.replace(`/mission/${missionId}`, '')

    const routesArray = Object.values(ROUTES).map(route =>
      generateRoute(route, { missionId, topicId })
    )

    const availableRoute = routesArray.find(route => route === pathName) || ROUTES.ROOT

    return availableRoute
  }, [location?.pathname, missionId, topicId])

  // const userPoints = useMemo(() => {
  //   return getUserPoints()
  // }, [getUserPoints])

  const handleCloseModal = useCallback(() => {
    setIsCloseModalOpen(false)
  }, [])

  const handleCloseActions = useCallback(() => {
    if (selectedMission.isCurrent) {
      return setIsCloseModalOpen(true)
    }

    navigate(previousPathName)
  }, [navigate, previousPathName, selectedMission.isCurrent])

  const handleClose = useCallback(() => {
    handleCloseModal()
    navigate(previousPathName)
  }, [handleCloseModal, navigate, previousPathName])

  const handleFinish = useCallback(
    async (msgs, oaths, questions) => {
      const questionnairePayload = getQuestionnairePayload(questions)

      if (questionnairePayload) {
        addQueueTask({
          payload: {
            createdOn: DateTime.now().toJSDate(),
            missionId,
            userId: user.id,
            ...questionnairePayload,
          },
        })
      }

      if (selectedMission.isCurrent) {
        const timestamp = new Date().getTime()

        const payload = {
          isCompleted: true,
          completedAt: timestamp,
          isDirty: true,
        }

        const changes = {
          [selectedMission.topicId]: {
            missionsAndSeries: {
              [missionId]: {
                isCompleted: true,
                completedAt: timestamp,
              },
            },
          },
        }

        setMissionProgress(selectedMission.topicId, selectedMission.id, payload, true, changes)
        // updateUserPoints(selectedMission.pointsForCompletion || 0)
      }

      handleClose()
    },
    [
      addQueueTask,
      getQuestionnairePayload,
      handleClose,
      missionId,
      selectedMission?.id,
      selectedMission?.isCurrent,
      selectedMission?.topicId,
      setMissionProgress,
      // updateUserPoints,
      user?.id,
    ]
  )

  const handleGoal = useCallback(
    goalData => {
      const payload = createGoalPayload({
        ...goalData,
        createdBy: { type: 'mission', missionId: selectedMission.id },
      })

      createGoalAndGoalInstances(payload)
    },
    [createGoalAndGoalInstances, createGoalPayload, selectedMission.id]
  )

  if (isLoading) {
    return <OMissionSkeleton />
  }

  if (!selectedMission) {
    return null
  }

  return (
    <>
      <Mission
        user={user}
        mission={selectedMission}
        // points={userPoints}
        onFinish={handleFinish}
        onGoal={handleGoal}
        doesGoalExist={doesGoalExist}
        onClose={handleCloseActions}
        onQuestion={updateQuestionnaire}
        missionTitle={selectedMission?.title}
        translations={{
          author: t('mission.author'),
          steps: t('mission.steps'),
          congrats: t('summaryComponent.congrats'),
          description: t('summaryComponent.description'),
          completed: t('summaryComponent.completed'),
          ctaText: t('summaryComponent.ctaText'),
        }}
        shouldDisplayCover={false}
      />

      <OModal onClose={handleCloseModal} isOpen={isCloseModalOpen}>
        <div className="text-center">
          <h2 className="text-lg font-semibold">{t('modal.areYouSure')}</h2>
          <p>{t('modal.startFromScratch')}</p>
        </div>
        <div className="flex justify-center items-center mt-4 space-x-2">
          <AButton onClick={handleClose}>{t('confirm')}</AButton>
          <AButton outline onClick={handleCloseModal}>
            {t('cancel')}
          </AButton>
        </div>
      </OModal>
    </>
  )
}
