import { MSkeleton } from '@molecules/MSkeleton'

export const OTodaySkeleton = () => (
  <div>
    <MSkeleton width={160} height={34} />
    <div className="flex mt-4 -mr-3 sm:-mr-5 lg:mr-0 mb-6 md:mb-10 overflow-hidden">
      {[...Array(3).keys()].map(item => (
        <div
          key={item}
          className="last:lg:hidden flex flex-col justify-between w-44 h-64 mr-2 last:mr-0 pt-2.5 px-2.5 md:px-4 pb-7 bg-white rounded-lg !rounded-tl-none"
        >
          <div className="ml-auto">
            <MSkeleton height={22} width={22} rounded="full" />
          </div>
          <MSkeleton height={28} width={140} />
        </div>
      ))}
    </div>
  </div>
)
