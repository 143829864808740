import React, { Fragment, useState } from 'react'
import classnames from 'classnames'

import useStore from '@store/useStore'

import { useTranslations } from '@hooks'

import { AButton } from '@atoms/AButton'
import { AIconChevronRight } from '@atoms/AIcons'
import { MSkeleton } from '@molecules/MSkeleton'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OResetPasswordModal } from '@organisms/OResetPasswordModal'
import { OSignoutModal } from '@organisms/OSignoutModal'
import { OAccountSkeleton } from '@organisms/OAccountSkeleton'
import { MLanguageSelect } from '@molecules/MLanguageSelect'

const AccountItem = ({ children, label, value, onClick, isLoading }) => {
  return (
    <button
      onClick={onClick}
      tabIndex={onClick ? 0 : -1}
      className={classnames(
        'flex justify-between items-end py-4',
        'w-full text-left focus:outline-none focus:ring-2 focus:ring-codGray',
        'border-b border-gray-200 last:border-b-0',
        onClick ? 'cursor-pointer rounded-lg' : 'cursor-auto pointer-events-none'
      )}
    >
      <div className="max-w-full">
        <dt className="mb-1 text-xs leading-[18px] text-paleSky">{label}</dt>
        <dd className="text-base leading-[18px]">
          {isLoading ? (
            <div className="flex-grow">
              <MSkeleton height={30} width={160} />
            </div>
          ) : (
            <span
              className={classnames(
                'flex-grow inline-block min-h-[19px] max-w-full truncate',
                label === 'Email' ? 'border-b border-codGray' : ''
              )}
            >
              {value}
            </span>
          )}
        </dd>
      </div>
      {children}
    </button>
  )
}

export const PAccount = ({ isLoading }) => {
  const { t } = useTranslations()

  const team = useStore(state => state.team)
  const user = useStore(state => state.user)

  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false)

  const handleOpenResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true)
  }

  const handleCloseResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false)
  }

  const handleOpenSignoutModal = () => {
    setIsSignoutModalOpen(true)
  }

  const handleCloseSignoutModal = () => {
    setIsSignoutModalOpen(false)
  }

  return (
    <OPageWrapper>
      <div className="sm:max-w-lg">
        {isLoading ? (
          <OAccountSkeleton />
        ) : (
          <>
            <h4 className="text-2xl tracking-[0.48px] font-p22">{t('label.account')}</h4>
            <dl className="mt-3 px-6 rounded-lg bg-white">
              <AccountItem label={t('label.name')} value={`${user?.firstName} ${user?.lastName}`} />
              <AccountItem label={t('label.email')} value={user?.email} />
              <AccountItem
                label={t('label.password')}
                value="******"
                onClick={handleOpenResetPasswordModal}
              >
                <AIconChevronRight />
              </AccountItem>
              {/*<div*/}
              {/*  className={classnames(*/}
              {/*    'p-3 bg-white sm:rounded-lg',*/}
              {/*    'w-full text-left focus:outline-none focus:ring-2 focus:ring-codGray'*/}
              {/*  )}*/}
              {/*>*/}
              {/*  <dt className="mb-2 text-xs leading-[18px] text-gray-500">{t('label.language')}</dt>*/}
              {/*  <div className="max-w-sm sm:w-44">*/}
              {/*    <MLanguageSelect />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </dl>
          </>
        )}
        <div className="mt-10 sm:mt-7">
          <AButton
            outline
            onClick={handleOpenSignoutModal}
            className="w-full sm:w-auto max-w-sm mx-auto sm:mx-0 sm:px-32"
          >
            {t('signOut')}
          </AButton>
        </div>
      </div>

      <OResetPasswordModal
        email={user?.email}
        isOpen={isResetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
      />

      <OSignoutModal isOpen={isSignoutModalOpen} onClose={handleCloseSignoutModal} />
    </OPageWrapper>
  )
}
