import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { getSanityImage, useAuthor } from '@edwin/react-sdk'

import ProgressiveImage from 'react-progressive-graceful-image'
import { ACloseIcon } from '../atoms/ACloseIcon'

export const OMissionDetailsSidebar = ({
  title = '',
  imageUrl,
  imageMeta,
  steps,
  activeStep = null,
  translations,
  shouldDisplayCloseIcon = true,
  onClose = () => {},
}) => {
  const { author } = useAuthor()

  const textTranslations = {
    author: 'with ',
    steps: "What we'll cover",
    ...translations,
  }

  return (
    <div className="hidden lg:block w-[300px]">
      <div className="fixed top-0 w-[300px] h-screen text-codGray bg-white shadow-xl overflow-hidden">
        {shouldDisplayCloseIcon && (
          <div className="relative">
            <div className="absolute top-2 left-1 z-40">
              <ACloseIcon iconName="chevronLeft" onClick={onClose} />
            </div>
          </div>
        )}
        {imageUrl && (
          <ProgressiveImage
            placeholder={getSanityImage(imageUrl, {
              width: 100,
              height: 100,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
              quality: 20,
            })}
            src={getSanityImage(imageUrl, {
              width: 600,
              height: 600,
              crop: imageMeta?.crop,
              hotspot: imageMeta?.hotspot,
            })}
          >
            {src => <img className="w-full h-72 object-cover m-0 p-0" src={src} alt="Author" />}
          </ProgressiveImage>
        )}
        <div className={classnames('p-7', { 'pt-14': !imageUrl && shouldDisplayCloseIcon })}>
          {title && (
            <>
              <h2 className="text-4xl font-medium font-p22">{title}</h2>
              <div className="mt-2 w-16 h-1 bg-codGray" />
            </>
          )}
          {steps?.length && (
            <div className="mt-10">
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={classnames(
                    'mb-4 pb-4 text-xl border-b border-[#C4C4C4]',
                    activeStep <= index ? '' : 'font-bold'
                  )}
                >
                  <p className="text-sm">{step}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

OMissionDetailsSidebar.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  imageUrl: PropTypes.string,
  imageMeta: PropTypes.object,
  onClose: PropTypes.func,
  authorName: PropTypes.string,
  activeStep: PropTypes.number,
}
