import { useCallback } from 'react'
import { DateTime } from 'luxon'
import ShortUniqueId from 'short-unique-id'
import { getTimeFromString } from '../helpers/getTimeFromString.js'

const createInstances = timeSettings => {
  const uid = new ShortUniqueId({ length: 15 })
  const {
    startOffset = 0,
    instanceDuration = 1,
    daysBetweenInstances = 0,
    requiredTrackings = 1,
    totalInstances = 1,
  } = timeSettings

  const arrayOfInstances = [...Array(totalInstances).keys()].reduce(
    (instances, currentInstanceNumber) => {
      const instanceDurationWithDaysBetween = instanceDuration + daysBetweenInstances
      const startOfCurrentInstance = currentInstanceNumber
        ? instanceDurationWithDaysBetween * currentInstanceNumber
        : startOffset

      const instanceDay = DateTime.utc().plus({ days: startOfCurrentInstance })
      const timezoneOffset = -DateTime.local().offset

      const currentInstance = {
        id: uid(),
        startsOn: instanceDay.startOf('day').plus({ minutes: timezoneOffset, seconds: 1 }),
        endsOn: instanceDay
          .endOf('day')
          .plus({ days: instanceDuration - 1, minutes: timezoneOffset }),
        requiredTrackings,
      }

      return [...instances, currentInstance]
    },
    []
  )

  return arrayOfInstances
}

const createNotRepeatingInstance = () => {
  const uid = new ShortUniqueId({ length: 15 })
  const instanceDay = DateTime.utc()
  const timezoneOffset = -DateTime.local().offset
  const notRepeatingGoalDurationInDays = 90

  return [
    {
      id: uid(),
      startsOn: instanceDay.startOf('day').plus({ minutes: timezoneOffset, seconds: 1 }),
      endsOn: instanceDay
        .endOf('day')
        .plus({ days: notRepeatingGoalDurationInDays, minutes: timezoneOffset }),
    },
  ]
}

const addRemindersToInstances = (instances = [], remindersTemplates = []) => {
  return instances.map(instance => {
    const reminders = remindersTemplates.map(({ title, prompt, description, time }) => {
      const reminderHour = getTimeFromString(time)
      const currentZoneOffset = DateTime.now().offset / 60
      const whenToSend = instance.startsOn
        .plus({ hour: reminderHour - currentZoneOffset })
        .minus({ seconds: 1 })

      return {
        whenToSend,
        meta: { title, subtitle: prompt, body: description },
      }
    })

    return {
      ...instance,
      reminders,
    }
  })
}

export const useGoal = () => {
  const createGoalPayload = useCallback(
    ({
      templateId,
      title,
      prompt,
      description,
      type,
      typeMeta,
      timeSettings,
      isRepeating,
      allowComments,
      allowMultipleTrackingsPerInstance,
      createdBy,
      remindersTemplates,
    }) => {
      const uid = new ShortUniqueId({ length: 15 })
      const goal = {
        templateId,
        title,
        prompt,
        description,
        type,
        typeMeta,
        isRepeating,
        allowComments,
        timeSettings,
        allowMultipleTrackingsPerInstance,
        createdBy,
        remindersTemplates,
      }

      // if (!isRepeating) {
      //   const instances = createNotRepeatingInstance()

      //   return [{ goal: { id: uid(), ...goal }, instances: instances }]
      // }

      const instances = createInstances(timeSettings)
      const instancesWithReminders = addRemindersToInstances(instances, remindersTemplates)

      return [{ goal: { id: uid(), ...goal }, instances: instancesWithReminders }]
    },
    []
  )

  return { createGoalPayload }
}
