import React, { forwardRef, useCallback, useState } from 'react'
import { getSanityImage } from '@edwin/react-sdk'
import classnames from 'classnames'

import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'
import { MLockedMissionModal } from '@molecules/MLockedMissionModal'

export const OMissionCover = forwardRef(
  ({ mission, onClick = () => {}, className, isActive }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const { coverImageUrl, coverImageMeta, isCurrent, isCompleted, isLocked, startsOn } = mission
    // const isLocked = !isCompleted && !isCurrent

    const placeholderImage = getSanityImage(coverImageUrl, {
      width: isActive ? 296 : 293,
      height: isActive ? 410 : 320,
      crop: coverImageMeta?.crop,
      hotspot: coverImageMeta?.hotspot,
      quality: 30,
    })

    const coverImage = getSanityImage(coverImageUrl, {
      width: (isActive ? 296 : 293) * 3,
      height: (isActive ? 410 : 320) * 3,
      crop: coverImageMeta?.crop,
      hotspot: coverImageMeta?.hotspot,
    })

    const handleOpenModal = useCallback(() => {
      setIsModalOpen(true)
    }, [])

    const handleCloseModal = useCallback(() => {
      setIsModalOpen(false)
    }, [])

    return (
      <>
        <button
          ref={ref}
          onClick={isLocked ? handleOpenModal : onClick}
          className={classnames(
            'relative flex flex-col items-start bg-white rounded-lg !rounded-tl-none overflow-hidden transition-opacity',
            'my-1 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-codGray cursor-pointer',
            isLocked ? '' : 'hover:opacity-90',
            isActive
              ? 'w-60 h-[350px] lg:w-[296px] lg:h-[410px]'
              : 'w-48 h-72 lg:w-56 lg:h-[320px]',
            className
          )}
        >
          <div className="absolute top-3 right-3 flex flex-col">
            {isCompleted && <AIconCompleted className="w-5 h-5 mb-1.5 bg-codGray/90 opacity-80" />}
            {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500 opacity-80" />}
          </div>
          <div className="h-full w-full overflow-hidden">
            {coverImage && (
              <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
                {src => (
                  <img className="w-full h-full object-cover" src={src} alt="mission cover" />
                )}
              </ProgressiveImage>
            )}
          </div>
          <div className="flex-1 py-4 px-4 text-left">
            <h4
              className={classnames(
                'font-p22 font-medium line-clamp-4',
                isLocked ? 'text-lg lg:text-xl opacity-50' : 'text-xl lg:text-2xl'
              )}
            >
              {mission.title}
            </h4>
            {startsOn && (
              <p className="mt-2 text-sm font-medium uppercase opacity-50">{startsOn}</p>
            )}
          </div>
        </button>

        <MLockedMissionModal isOpen={isModalOpen} onClose={handleCloseModal} />
      </>
    )
  }
)
