import { create } from 'zustand'
import { DateTime } from 'luxon'

let ephemeralStore = null

export const createEphemeralStore = () => {
  if (ephemeralStore) return ephemeralStore

  ephemeralStore = create((set, get) => ({
    dateNow: null,

    /**
     *
     * @param {*} dateNow<Date>
     */
    updateDateNow: async dateNow => {
      const dateNowFinal = dateNow
        ? dateNow.startOf('day').plus({ seconds: 1 })
        : DateTime.now().startOf('day').plus({ seconds: 1 })

      set({ dateNow: dateNowFinal })
    },

    resetStore: async () => {
      set({ dateNow: null })
    },
  }))

  return ephemeralStore
}

export default createEphemeralStore
