import React from 'react'
import './index.scss'

import {
  AuthorProvider,
  UserProvider,
  GoalProvider,
  ChatComponentProvider,
  SummaryProvider,
} from '@edwin/react-sdk'
import { configChatComponents } from './utils/configChatComponents'

import { OMissionDetails } from './components/organisms/OMissionDetails'

export const Mission = ({
  mission = {},
  user = {
    firstName: '',
    lastName: '',
  },
  chatComponents = {},
  onUserAction,
  onClose,
  onFinish,
  onExit,
  onProgress,
  onGoal,
  doesGoalExist,
  onStepUpdated,
  onQuestion,
  points,
  missionTitle,
  translations,
  shouldScrollItself = false,
  shouldDisplayCover = true,
  shouldDisplaySidebar = true,
  shouldDisplayCloseIcon = true,
}) => {
  const { author = null, pointsForCompletion, isCurrent = false } = mission

  return (
    <AuthorProvider author={author}>
      <UserProvider user={user}>
        <GoalProvider onGoal={onGoal} doesGoalExist={doesGoalExist}>
          <ChatComponentProvider
            customChatComponents={chatComponents}
            defaultChatComponents={configChatComponents}
          >
            <SummaryProvider
              missionTitle={missionTitle}
              points={points}
              pointsForCompletion={pointsForCompletion}
              isCurrent={isCurrent}
              translations={translations}
            >
              <div className="react-web--beautyedit__container" style={{ height: '100%' }}>
                <OMissionDetails
                  mission={mission}
                  onUserAction={onUserAction}
                  onClose={onClose}
                  onFinish={onFinish}
                  onExit={onExit}
                  onProgress={onProgress}
                  onStepUpdated={onStepUpdated}
                  onQuestion={onQuestion}
                  translations={translations}
                  shouldScrollItself={shouldScrollItself}
                  shouldDisplayCover={shouldDisplayCover}
                  shouldDisplaySidebar={shouldDisplaySidebar}
                  shouldDisplayCloseIcon={shouldDisplayCloseIcon}
                />
              </div>
            </SummaryProvider>
          </ChatComponentProvider>
        </GoalProvider>
      </UserProvider>
    </AuthorProvider>
  )
}
