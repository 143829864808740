import { useCallback, useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'
import { useActiveTopics, useUpdateLeaderboard, useTranslations, useProgramTimeline } from '@hooks'
import { checkIfMobile } from '@utils/helpers'
import classnames from 'classnames'

import useStore from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
// import usePointsStore from '@store/usePointsStore'

import illustration from '@assets/images/people.svg'

import { AHeading } from '@atoms/AHeading'
import { MSlider } from '@molecules/MSlider'
import { MPointsStats } from '@molecules/MPointsStats'
import { MLeaderboard } from '@molecules/MLeaderboard'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OMissionCover } from '@organisms/OMissionCover'
import { OTopicModal } from '@organisms/OTopicModal'
import { OTodaySkeleton } from '@organisms/OTodaySkeleton'

export const PToday = ({ isLoading }) => {
  const { t } = useTranslations()
  // const [isMobile, setIsMobile] = useState(checkIfMobile())

  const navigate = useNavigate()

  // useUpdateLeaderboard()

  // const { uid } = useStore(state => ({
  //   uid: state.user?.id,
  // }))
  // const team = useStore(state => state.team)

  // const userProgress = useProgressStore(state => state.userProgress)
  // const getTopicsLength = useProgressStore(state => state.getTopicsLength)
  // const getCompletedAssignmentsNumber = useProgressStore(
  //   state => state.getCompletedAssignmentsNumber
  // )

  // const activeTopics = useActiveTopics()
  const { currentProgramDay, activeTopics } = useProgramTimeline()

  // const teamLeaderboard = usePointsStore(state => state.teamLeaderboard)
  // const getUserPoints = usePointsStore(state => state.getUserPoints)

  // const topicsLength = useMemo(() => {
  //   const progressTopicsNumber = getTopicsLength()
  //   const notStartedTopicsNumber = activeTopics?.reduce((acc, currVal) => {
  //     const topicIsStarted = !!currVal.missionsAndSeries.find(
  //       ({ isCompleted = false }) => isCompleted
  //     )
  //
  //     return topicIsStarted ? acc : acc + 1
  //   }, 0)
  //
  //   return progressTopicsNumber + notStartedTopicsNumber
  // }, [activeTopics, getTopicsLength])

  // const completedAssignmentsNumber = useMemo(() => {
  //   return getCompletedAssignmentsNumber(userProgress)
  // }, [getCompletedAssignmentsNumber, userProgress])

  // const userPoints = useMemo(() => {
  //   return getUserPoints(teamLeaderboard)
  // }, [getUserPoints, teamLeaderboard])

  const handleClickLeaderboard = useCallback(() => {
    navigate(ROUTES.LEADERBOARD)
  }, [navigate])

  const handleClickMission = useCallback(
    (topicId, missionId) => {
      navigate(generateRoute(ROUTES.MISSION, { topicId, missionId }))
    },
    [navigate]
  )

  // const handleResize = useCallback(() => {
  //   setIsMobile(checkIfMobile())
  // }, [])

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize)
  //
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [handleResize])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OTodaySkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper
      contentClassName="!min-h-[260px] md:!min-h-[320px] lg:!min-h-[450px]"
      pageIllustration={
        <img
          src={illustration}
          alt=""
          className="absolute -bottom-9 right-5 md:-bottom-16 md:right-16 lg:-bottom-20 lg:right-20 w-[224px] md:w-[290px] lg:w-[406px]"
        />
      }
    >
      <div className="mb-4">Today is day {currentProgramDay} of the program</div>

      {activeTopics?.map(({ id, name, missionsAndSeries }) => {
        const activeItem = missionsAndSeries.findIndex(({ isCurrent }) => isCurrent) || 0

        return (
          <div key={id}>
            {/*<AHeading>{t('topicHeading', { topicName: name })}</AHeading>*/}
            <AHeading>{name}</AHeading>
            <MSlider
              startIndex={activeItem}
              className="mt-4 -mx-3 sm:-mx-5 lg:mx-0 mb-6 md:mb-10 overflow-x-hidden"
              sliderClassName="items-start pr-3 lg:pr-1"
              leftPaddingPlaceholder={<div className="ml-3 sm:ml-5 lg:ml-0 -mr-1.5" />}
            >
              {missionsAndSeries.map((missionOrSeries, index) => (
                <OMissionCover
                  key={index}
                  mission={missionOrSeries}
                  isActive={activeItem === index}
                  className={classnames('snap-center flex-shrink-0 mx-1.5 last:mr-0')}
                  onClick={() => {
                    handleClickMission(id, missionOrSeries.id)
                  }}
                />
              ))}
            </MSlider>
          </div>
        )
      })}

      <OTopicModal />
    </OPageWrapper>
  )
}
