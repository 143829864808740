export const ROUTES = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD: '/sign-in-with-email-and-password',
  ONBOARD: '/onboard',
  TODAY: '/today',
  MISSION: '/topic/:topicId/mission/:missionId',
  // LEADERBOARD: '/leaderboard',
  PROGRAM: '/program',
  PROGRAM_TOPIC: '/program/topic/:topicId',
  PROGRAM_TOPIC_MISSION: '/program/topic/:topicId/mission/:missionId',
  // CHAT: '/chat',
  ACCOUNT: '/account',
}

export const ROUTE_NAMES = {
  HOME: 'Home',
  SIGN_IN: 'Sign In',
  ONBOARDING: 'Onboard',
  TODAY: 'Today',
  MISSION: 'Mission',
  // LEADERBOARD: 'Leaderboards',
  PROGRAM: 'Program',
  PROGRAM_TOPIC: 'Progress Topic',
  PROGRAM_TOPIC_MISSION: 'Mission',
  // CHAT: 'Chat',
  ACCOUNT: 'Account',
}

export const generateRoute = (routeName = '', params = {}) => {
  const { missionId, topicId } = params

  return routeName.replace(':missionId', missionId).replace(':topicId', topicId)
}

export const getLocationRouteName = pathname => {
  const matchingRoutesNames = {
    '^/$': ROUTE_NAMES.HOME,
    '^/sign-in$': ROUTE_NAMES.SIGN_IN,
    '^/onboard$': ROUTE_NAMES.ONBOARDING,
    '^/today$': ROUTE_NAMES.TODAY,
    '^/topic/[^/]+/mission/[^/]+$': ROUTE_NAMES.MISSION,
    // '^/leaderboard$': ROUTE_NAMES.LEADERBOARD,
    '^/program$': ROUTE_NAMES.PROGRAM,
    '^/program/topic/[^/]+$': ROUTE_NAMES.PROGRAM_TOPIC,
    '^/program/topic/[^/]+/mission/[^/]+$': ROUTE_NAMES.PROGRAM_TOPIC_MISSION,
    // '^/chat$': ROUTE_NAMES.CHAT,
    '^/account$': ROUTE_NAMES.ACCOUNT,
  }

  for (let pattern in matchingRoutesNames) {
    if (new RegExp(pattern).test(pathname)) {
      return matchingRoutesNames[pattern]
    }
  }

  return null
}
